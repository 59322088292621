import {
  faTwitter,
  faDiscord,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./homepage.css";

export const Homepage = () => {
  return (
    <>
      <div className="homepage">
        <div className="background" />
        <div className="main-container">
          <div className="navbar">
            <a href="https://twitter.com">
              <FontAwesomeIcon icon={faTwitter} className="fontawesome" />
            </a>
            <a href="https://discord.com">
              <FontAwesomeIcon icon={faDiscord} className="fontawesome" />
            </a>
            <a href="https://medium.com">
              <FontAwesomeIcon icon={faMedium} className="fontawesome" />
            </a>
          </div>
          <div className="title-container">
            <div className="logo" />
            <span className="title">
              BUBBLE
              <br />
              OBSERVERS
            </span>
          </div>
          <div className="status-container">
            <span className="status">
              SEASON 1
              <br />
              LANDING NOW
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
